<style>
.txt {
    font-size: 14pt;
    color: black;
    text-align: center;
    cursor: pointer;
}

.txt:hover {
    text-decoration: underline;
}

.modal-dialog {
    height: auto;
}

.modal-content {
    height: auto;
    min-height: auto;
}

.card-body {
    max-height: auto;
}
</style>

<template>
  <div>
    <h2 class="text-center"> Your Phone Number: <em>{{ $toInternationalFormat(selectedNumber,countryCode)}}</em></h2>
    <h4 class="text-center"> Region: <em>{{ $t(`${countryCode}.${selectedRegion}`) }}</em></h4>
    <h5 class="text-center"> Locality: <em>{{ selectedLocality }}</em></h5>

    <hr>
    <div class="text-end">
      <a href="javascript:void(0)" class="toggle-code-snippet" @click="toggleAdvanceSearch">
        Advanced Search
      </a>
    </div>

    <div v-if="advanceSearch">
      <div class="row mb-4 mt-4">
        <div class="col-sm-5 col-mb-12 col-12 mb-3">
          <label>State</label>
          <selectBox
            :options="states[countryCode]"
            v-model="filter.region"
          />
        </div>
        <div class="col-sm-2 col-mb-12 col-12 mb-3">
          <label>Area Code</label>
          <input
            v-model="filter.areaCode"
            type="number"
            class="form-control form-control-sm"
            placeholder="e.g.: 323 (for Los Angeles)"
          />
        </div>
        <div class="col-sm-3 col-mb-12 col-12">
          <label>Number contains</label>
          <input
            v-model="filter.contains"
            type="number"
            class="form-control form-control-sm"
            placeholder="e.g.: 777"
          />
        </div>
        <div class="col-sm-2 col-mb-12 col-12">
          <label>&nbsp;<br><br><br></label>
          <a type="button" class="btn btn-outline-primary" @click="searchMore">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-search"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </a>
        </div>
      </div>
    </div>

    <div class="table-responsive" v-if="moreResults.length > 0 && advanceSearch==true">
      <table
        role="table"
        aria-busy="false"
        aria-colcount="5"
        class="table table-bordered table-striped table-hover"
      >
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col" aria-colindex="1">
              <div>Phone Number</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="2">
              <div>State</div>
            </th>
            <th role="columnheader" scope="col" aria-colindex="3">
              <div>Locality</div>
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <tr
            v-for="[ index, item] of moreResults.entries()"
            :key="item.phoneNumber"
            role="row"
            style="cursor: pointer"
            @click="selectNumber(index)"
          >
            <td aria-colindex="1" role="cell">
              {{ $toInternationalFormat(item.phoneNumber, item.country) }}
            </td>
            <td aria-colindex="2" role="cell">{{ $t(`${countryCode}.${item.region}`) }}</td>
            <td aria-colindex="3" role="cell">{{ item.locality }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { getCurrentInstance } from 'vue';
const instance = getCurrentInstance();

const { $get, $toInternationalFormat } = useNuxtApp();
const selectedNumber = ref("");
const selectedRegion = ref("");
const selectedLocality = ref("");

const advanceSearch = ref(false);
const madeInitialRequest = ref(false);
const moreResults = ref([]);
const isSearching = ref(false);

const i18n = useI18n();

const filter = ref({region:-1});
const stateList = {
                  "US": [
                    -1, "AL",  "AK",  "AZ",  "AR",  "CA",  "CO",  "CT",  "DE",  "FL",  "GA",  "HI",  "ID",  "IL",  "IN",  "IA",  "KS",  "KY",  "LA",  "ME",  "MD",  "MA",  "MI",  "MN",  "MS",  "MO",  "MT",  "NE",  "NV",  "NH",  "NJ",  "NM",  "NY",  "NC",  "ND",  "OH",  "OK",  "OR",  "PA",  "RI",  "SC",  "SD",  "TN",  "TX",  "UT",  "VT",  "VA",  "WA",  "WV",  "WI", "WY" 
                  ],
                  "CA": [-1, "AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"]};

const countryCode = ref();
const selectNumber = (index) => {
    // alert(JSON.stringify(moreResults.value[index]));
    selectedNumber.value = moreResults.value[index].phoneNumber;
    selectedRegion.value = moreResults.value[index].region;
    selectedLocality.value = moreResults.value[index].locality;
    sessionStorage.setItem("selectedDID", selectedNumber.value);
    instance.emit('update:modelValue', {phoneNumber:selectedNumber.value, region:selectedRegion.value, locality:selectedLocality});
}

const getRandomNumber = async () => {
  const selectedNumberData = await $get("/api/protected/did/available/random");

  const { phoneNumber, country, region, locality } = selectedNumberData.data;
  countryCode.value = country;
  selectedNumber.value = $toInternationalFormat(phoneNumber, country);
  sessionStorage.setItem("selectedDID", phoneNumber);
  
  selectedRegion.value = region;
  selectedLocality.value = locality;
  instance.emit('update:modelValue', {phoneNumber:phoneNumber, region:selectedRegion.value, locality:selectedLocality, formatted: selectedNumber.value});
};

defineExpose({
  getRandomNumber
});

let states = ref({"US":[], "CA":[]})
  for (let key in stateList ){
      for (let item of stateList[key] ){
        states.value[key].push({value: item, name: i18n.t(`${key}.${item}`)})
      } 
    }

    


const toggleAdvanceSearch = () => {
  advanceSearch.value = !advanceSearch.value;
};

async function searchMore() {
  madeInitialRequest.value = true;
  moreResults.value = [];
  let myFilter = [];
  if ("region" in filter.value && filter.value.region != null) {
    myFilter.push(`inRegion=${filter.value.region}`);
  }
  if ("areaCode" in filter.value) {
    if (filter.value.areaCode != "") myFilter.push(`areaCode=${filter.value.areaCode}`);
  }
  if ("contains" in filter.value) {
    if (filter.value.contains != "") myFilter.push(`contains=${filter.value.contains}`);
  }
  isSearching.value = true;
  let url = `/api/protected/did/available?limit=10&${myFilter.join("&")}`;

  let encodedURL = encodeURI(url);

  let response = await $get(encodedURL);
  moreResults.value = response.data;

  isSearching.value = false;
}
</script>